<template>
  <div class="HomeView">
    <!-- <div class="pattern" :style="style"></div> -->
    <div class="header">
      <div class="headerEdge headerEdge--left">
        <div
          class="exitButton --global-clickable"
          @click="exit()">
          <svgicon
            class="exitIcon"
            icon="dashboard-icon" />
        </div>
      </div>
      <div class="title">
        {{ $t('systems.name.name') }}
      </div>
      <div class="headerEdge headerEdge--right" />
    </div>

    <div class="body">
      <div class="tagline">
        {{ $t('tagline') }}
      </div>
      <div class="spacer-1" />
      <!-- <NavigationWithLabels :labels="placesLabels" :index="index" @click="select"/> -->

      <div class="categories">
        <Category
          v-for="categoryId of selectedPlace.categoryIds"
          :key="categoryId"
          class="--global-clickable"
          :category-id="categoryId"
          @click.native="gotoCategory(categoryId)" />
      </div>
      <div class="spacer" />
    </div>

    <!-- TODO: enable footer -->
    <!-- <div class="footer" :style="{ color: theme.primaryColor }">    -->
    <!--   <div class="footerItem">                                     -->
    <!--     <svgicon class="icon" icon="filter"/>                      -->
    <!--     <div class="footerItemLabel">{{ $t('codes') }}</div>       -->
    <!--   </div>                                                       -->
    <!--   <div class="footerItem">                                     -->
    <!--     <div class="dueCount">12</div>                             -->
    <!--     <div class="footerItemLabel">{{ $t('repetitions') }}</div> -->
    <!--   </div>                                                       -->
    <!--   <div class="footerItem">                                     -->
    <!--     <svgicon class="icon" icon="library"/>                     -->
    <!--     <div class="footerItemLabel">{{ $t('library') }}</div>     -->
    <!--   </div>                                                       -->
    <!-- </div>                                                         -->
  </div>
</template>

<translations>
  tagline: 'Remember names the smart way'
  tagline_no: 'Husk navn på den smarte måten'
  codes: 'Codes'
  codes_no: 'Koder'
  repetitions: 'Repetitions'
  repetitions_no: 'Repetisjoner'
  library: 'Library'
  library_no: 'Bibliotek'
</translations>

<script>
import Category from '../components/Category';

export default {
    components: { Category },
    inject: ['module', 'theme'],
    data() {
        return {
            index: 0
        };
    },
    computed: {
        style() {
            return {
                backgroundImage: this.theme.coverImage ? `url(${this.theme.coverImage})` : null
            };
        },
        places() {
            return this.module.state.places;
        },
        placesLabels() {
            return this.places.map(p => this.$t(`country.${p.name}`));
        },
        categoryIds() {
            return this.module.state.categoryIds;
        },
        selectedPlace() {
            return this.places[this.index];
        }
    },
    methods: {
        exit() {
            this.$router.push(this.$store.state.moduleApp.memolife.systemExitTo);
        },
        back() {
            this.$router.push({ name: 'CoverView' });
        },
        select(index) {
            this.index = index;
        },
        gotoCategory(categoryId) {
            this.$router.push({ name: 'NameSystemCategoryView', params: { categoryId } });
        }
    }
};
</script>

<style scoped lang="scss">
.HomeView {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // padding: 2em 2em;
    // padding-top: 3em;

    & > * {
        position: relative;
    }
}
.header {
    height: $names-headerHeight;
    display: flex;
    align-items: center;
}
.headerEdge {
    width: 4em;
    align-self: stretch;
    display: flex;
}
.body {
    flex: 1;
    padding: 2em 2em;
}
.pattern {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 50%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
}

.exitIcon {
    $size: 1.5em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.6);
}
.exitButton {
    align-self: stretch;
    display: flex;
    align-items: center;
    padding: 0 1em;
    padding-left: 2em;
}

.spacer {
    flex: 1;
}
.spacer-1 {
    flex: 1;
    max-height: 5em;
    height: 4em;
}

.categories {
    align-self: stretch;
    margin-top: 2em;
}
.Category {
    // width: 100%;
    &:not(:first-child) {
        margin-top: 1em;
    }
}
.title {
    font-size: 120%;
    color: rgba(white, 0.6);
    text-align: center;
    flex: 1;
    letter-spacing: 0.05em;
}
.tagline {
    margin-top: 0.5em;
    color: rgba(white, 0.1);
    text-align: center;
    font-size: 180%;
}

.footer {
    display: flex;
    align-self: stretch;
    justify-content: space-between;

    padding: 1em 2em;
}

.footerItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dueCount {
    font-size: 70%;
    font-weight: 600;
    background-color: red;
    color: white;
    border-radius: 2em;
    padding: 0.2em 0.3em;
    color: white;
}

.footerItemLabel {
    margin-top: 0.5em;
    color: rgba(white, 0.4);
}

.icon {
    $size: 1.2em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.5);
}
</style>
