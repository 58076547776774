<template>
  <div class="Category">
    <div class="head">
      <div
        class="icon"
        :style="{ backgroundColor: category.color }">
        <svgicon
          class="svg-icon"
          :icon="category.type" />
      </div>
    </div>
    <div class="body">
      <div class="tag">
        {{ $t('top100') }}
      </div>
      <div class="title">
        {{ $t(`${category.type}`) }}
      </div>
    </div>
  </div>
</template>

<translations>
  top100: 'Top 100'
  top100_no: 'Topp 100'
  male: 'Male names'
  male_no: 'Mannsnavn'
  female: 'Female names'
  female_no: 'Kvinnenavn'
  surnames: 'Surnames'
  surnames_no: 'Etternavn'
</translations>

<script>
export default {
    inject: ['module'],
    props: {
        categoryId: {
            type: String,
            required: true
        }
    },
    computed: {
        category() {
            return this.module.state.categories[this.categoryId];
        }
    }
};
</script>

<style scoped lang="scss">
.Category {
    border-radius: 0.5em;
    // overflow: hidden;
    padding: 0.5em;
    background-color: white;
    color: mix(black, white, 80%);
    display: flex;
    align-items: center;
}
.body {
    margin-left: 1em;
}
.icon {
    $size: 5em;
    width: $size;
    height: $size;
    background-color: rgba(black, 0.1);
    border-radius: 0.3em;

    display: flex;
    justify-content: center;
    align-items: center;
}
.svg-icon {
    $size: 2em;
    width: $size;
    height: $size;
    fill: white;
}
.name {
    flex: 1;
}

.tag {
    color: rgba(black, 0.5);
    margin-bottom: 0.5em;
}
.title {
    font-size: 130%;
}
// .viewLabel {
//   color: rgba(black, 0.2);
//   font-size: 80%;
// }
</style>
